/**
 * @module Split Section Gamers
 * @description
 */
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import Image from 'next/legacy/image';
import { fastly, fastlyBlur } from '@/js/utils/helpers';
import PropTypes from 'prop-types';
import DynamicButton from '@/blocks/Buttons/DynamicButton';
import { trackEvent } from '@/js/helpers/tracking';
import state from '@/js/config/state';
import styles from './index.module.css';

const defaultImage = {
  url: '/migration/sections/split-section/man-red-beanie-gaming-tall-large.jpg',
  urlMobile: '/migration/sections/split-section/man-red-beanie-gaming-tall.jpg',
  urlTablet: '/migration/sections/split-section/man-red-beanie-gaming-tall-large.jpg',
  blurUrl: '/migration/sections/split-section/man-red-beanie-gaming-tall.jpg',
  alt: 'Man wearing a red beanie with headphones playing games'
};

const position = 'SPLIT SECTION GAMERS';

/**
 * @function Component
 * @description
 */
const Component = ( { ...props } ) => {
  const {
    assetName, title, content, items, theme = 'dark', image = defaultImage
  } = props;

  const options = {
    threshold: 1,
    triggerOnce: true
  };
  const { ref, entry } = useInView( options );

  useEffect( () => {
    if ( entry?.isIntersecting === true ) {
      const data = {
        elementType: 'SECTION',
        name: assetName,
        text: `${ position } - HEADLINE VIEWED`
      };
      trackEvent( { action: 'elementViewed', data, event: {} } );
    }
  }, [ entry, assetName ] );

  return (
    <section className={ `component ${ styles.component }` }>
      <div className={ `l-container ${ styles.component__background }` }>
        <div className={ `${ styles.component__layout } ${ theme && `u-theme-${ theme }` }` }>
            <div className={ styles.component__imgWrapper }>
              <Image
                alt={ image.alt }
                src={ state.is_mobile ? image.urlMobile : ( state.is_tablet ? image.urlTablet : image.url ) }
                loader={ fastly }
                placeholder="blur"
                loading="lazy"
                blurDataURL={ fastlyBlur( image.blurUrl ) }
                layout="fill"
                objectFit="cover"
              />
            </div>
            <div className={ styles.component__info }>
              <h2 ref={ ref } className={ styles.component__title }>{ title }</h2>
              <p className={ `${ styles.component__subtitle } h6` }>{ content }</p>
              <ul className={ styles.component__list }>
                { items.map( ( item, index ) => (
                  <li key={ index }>
                    <span className={ `icon icon-checkmark1 ${ styles.component__icon } ${ theme && styles[ `component__icon__${ theme }` ] }` } />
                    { item }
                  </li>
                ) ) }
              </ul>
              <div>
                <DynamicButton
                  type="cart"
                  position={ position }
                />
              </div>
            </div>
        </div>
      </div> { /* <!-- .grid-container --> */ }
    </section>
  );
};

Component.defaultProps = {
  assetName: 'SECTION | SPLIT SECTION GAMERS | DEFAULT',
  image: defaultImage,
  title: 'built for gaming',
  content: 'Upgrade to internet that won’t let you down.',
  items: [
    'Virtually eliminate lag with our high speed fiber network',
    'Game, stream and video chat on multiple devices'
  ],
  theme: 'dark'
};

Component.propTypes = {
  assetName: PropTypes.string,
  title: PropTypes.node,
  content: PropTypes.node,
  image: PropTypes.shape( {
    url: PropTypes.string,
    urlMobile: PropTypes.string,
    blurUrl: PropTypes.string,
    alt: PropTypes.string
  } ),
  items: PropTypes.array,
  theme: PropTypes.string
};

export default Component;
