/**
 * @module AddressCheckHero
 * @description
 */
import { useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import dynamic from 'next/dynamic';
import { FuelContext } from '@/providers/fuel-provider';
import ServiceCheckSingleLine from '@/blocks/ServiceCheckForm';
import { trackEvent } from '@/js/helpers/tracking';
import styles from './index.module.css';
import PropTypes from 'prop-types';
import state from '@/js/config/state';

const PhoneButton = dynamic( () => import( '@/blocks/Buttons/PhoneButton' ) );
const position = 'HERO ADDRESS CHECK';

/**
 * @function Component
 * @description Hero that displays headline and predictive service check
 */
const Component = ( props ) => {
  const {
    className = '',
    assetName,
    theme = 'dark',
    headline,
    headings, // editable asset headings object
    horizonHeadline,
    horizonHeadlineColors,
    subHeadline,
    background,
    mobileBackgroundCover,
    imageText,
    form,
    showFormLead,
    customStyles,
    useSelectAreasLegal
  } = props;
  const { phoneHeavy } = useContext( FuelContext );
  const backgroundPath = `${ process.env.imageDomain }${ background }`;

  const options = {
    threshold: 1,
    triggerOnce: true
  };
  const { ref, entry } = useInView( options );

  useEffect( () => {
    if ( entry?.isIntersecting === true ) {
      const data = {
        elementType: 'HERO',
        name: assetName,
        text: `${ position } - HEADLINE VIEWED`
      };
      trackEvent( { action: 'elementViewed', data, event: {} } );
    }
  }, [ entry, assetName ] );

  return (
    <section className={ `component ${ styles.component } ${ className } ${ theme && `u-theme-${ theme } ` } ${ mobileBackgroundCover ? styles.component__mobileBackgroundCover : styles.component__hideMobileBackground } ${ customStyles && customStyles.component }` } style={ { backgroundImage: `url(${ backgroundPath }--large.jpg )` } }>
      <div className={ `l-container ${ styles.component__wrapper } ${ customStyles && customStyles.component__wrapper }` }>
        <div className="g-row">
          <div className={ `g-col g-col--two-thirds ${ customStyles && customStyles.component__layout }` }>
            <div className={ `${ styles.component__contentWrapper } ${ customStyles && customStyles.component__contentWrapper }` }>
              { horizonHeadline ?
                <h2 ref={ ref } className={ `${ styles.component__headlineWrapper } ${ customStyles && customStyles.component__headlineWrapper }` }>
                  <span className={ `h1 component__horizonHeadline ${ styles.component__horizonHeadline } ${ horizonHeadlineColors && `u-color-${ horizonHeadlineColors[ 0 ] }` }` }>{ horizonHeadline[ 0 ] }</span>
                  <span className={ `h1 component__horizonHeadline ${ styles.component__horizonHeadline } ${ horizonHeadlineColors && `u-color-${ horizonHeadlineColors[ 1 ] }` }` }>{ horizonHeadline[ 1 ] }</span>
                  <span className={ `h1 component__horizonHeadline ${ styles.component__horizonHeadline } ${ horizonHeadlineColors && `u-color-${ horizonHeadlineColors[ 2 ] }` }` }>{ horizonHeadline[ 2 ] }</span>
                </h2> :
                <h2 ref={ ref } className={ `${ styles.component__headline } ${ customStyles && customStyles.component__headline } h1` }>
                  { headline }
                  { headings &&
                    <>
                      { headings.heading1 && <><span className={ headings.heading1Color && `u-color-${ headings.heading1Color }` }>{ headings.heading1 }</span><br/></> }
                      { headings.heading2 && <><span className={ headings.heading2Color && `u-color-${ headings.heading2Color }` }>{ headings.heading2 }</span><br/></> }
                      { headings.heading3 && <><span className={ headings.heading3Color && `u-color-${ headings.heading3Color }` }>{ headings.heading3 }</span><br/></> }
                    </>
                  }
                </h2>
              }

              <div className={ customStyles && customStyles.component__ctaContainer }>
                { phoneHeavy ?
                  <>
                    <h3 className='h4'>Call now to speak with an expert about the plans available in your area.</h3>
                    <PhoneButton
                      position={ position }
                    />

                    { useSelectAreasLegal && <p className={ styles.component__selectAreasLegal }>Frontier<sup>&reg;</sup> Fiber Internet available in select&nbsp;areas.</p> }
                  </> :
                  <>
                    { subHeadline && <h3 className={ `h4 ${ styles.component__subHeadline } ${ customStyles && customStyles.component__subHeadline }` }>{ subHeadline }</h3>}

                    { showFormLead &&
                      <p className={ `h6 ${ styles.component__formLead }` }>
                        <span className={ `icon icon-location-marker ${ styles.component__icon }` } />
                        <span>Enter your address to view plans in your area.</span>
                      </p>
                    }

                    { form || <ServiceCheckSingleLine /> }

                    { useSelectAreasLegal && <p className={ styles.component__selectAreasLegal }>Frontier<sup>&reg;</sup> Fiber Internet available in select&nbsp;areas.</p> }
                  </>
                }
              </div>
            </div>
          </div>
        </div>
        { ! mobileBackgroundCover && <div className={ `${ styles.component__mobileBackground } ${ customStyles && customStyles.component__mobileBackground }` } style={ { backgroundImage: `url( ${ backgroundPath }.jpg )` } }>{
          imageText && <p className={ styles.component__imageText }>{ imageText }</p>
        }</div> }
      </div>
      { ( ! state.is_mobile && imageText ) && <p className={ styles.component__imageText }>{ imageText }</p>}
    </section>
  );
};

Component.defaultProps = {
  assetName: 'HERO | ADDRESS CHECK | DEFAULT',
  theme: 'dark',
  headline: null,
  horizonHeadline: null,
  subHeadline: null,
  background: '/migration/sections/hero/lady-tablet-night-cityscape',
  imageText: null,
  form: null,
  showFormLead: false,
  useSelectAreasLegal: true,
  mobileBackgroundCover: false
};

Component.propTypes = {
  assetName: PropTypes.string,
  theme: PropTypes.string,
  headline: PropTypes.node,
  horizonHeadline: PropTypes.arrayOf( PropTypes.node ),
  subHeadline: PropTypes.node,
  background: PropTypes.string,
  imageText: PropTypes.string,
  form: PropTypes.node,
  showFormLead: PropTypes.bool,
  useSelectAreasLegal: PropTypes.bool,
  mobileBackgroundCover: PropTypes.bool
};

export default Component;
