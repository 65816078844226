/**
 * @module Split Section - Image + Content
 * @description Split Section 50/50
 */
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import Image from 'next/legacy/image';
import { fastly, fastlyBlur } from '@/js/utils/helpers';
import DynamicButton from '@/blocks/Buttons/DynamicButton';
import { trackEvent } from '@/js/helpers/tracking';
import styles from './index.module.css';

const position = 'SPLIT SECTION';

const defaultImage = {
  url: '/migration/pages/home/split-section-720x514.jpg',
  blurUrl: '/migration/pages/home/split-section-720x514.jpg',
  alt: 'Woman wearing VR - 2G text'
};

/**
 * @function Component
 * @description
 */
const Component = ( { ...props } ) => {
  const {
    assetName,
    customStyles,
    theme,
    title,
    subtitle,
    content,
    items,
    legal,
    ctaLink,
    ctaTheme,
    dualCta,
    dualCtaTheme,
    componentList,
    reverseLayout,
    reverseMobile,
    objectFit = 'cover',
    objectPosition = 'center',
    video
  } = props;

  let { image = defaultImage } = props;
  image = { ...image, defaultImage };

  const options = {
    threshold: 1,
    triggerOnce: true
  };
  const { ref, entry } = useInView( options );

  useEffect( () => {
    if ( entry?.isIntersecting === true ) {
      const data = {
        elementType: 'SECTION',
        name: assetName,
        text: `${ position } - HEADLINE VIEWED`
      };
      trackEvent( { action: 'elementViewed', data, event: {} } );
    }
  }, [ entry, assetName ] );

  return (
    <section className={ `component ${ styles.component } ${ reverseMobile && styles.componentMobileReverse } ${ theme && `u-theme-${ theme }` }` }>
      <div className={ `${ styles.component__backgroundWrap } ${ styles.component__backgroundWrapMobile } ${ ( objectFit === 'contain' ) && styles.component__backgroundWrapContain }` }>
        <div className={ `
          ${ styles.component__background }
          ${ styles.component__backgroundMobile }
          ${ customStyles && customStyles.component__backgroundMobile }` }>
          { video ?
            <video autoPlay loop muted>
              <source src={ video } type="video/mp4" />
            </video> :
            <Image
              alt={ image.alt }
              src={ image.url }
              loader={ fastly }
              placeholder="blur"
              loading="lazy"
              blurDataURL={ fastlyBlur( image.blurUrl ) }
              layout="fill"
            />
          }
        </div>{ /* <!-- .component__background --> */}
      </div>

      <div className={ `l-container ${ customStyles && customStyles.component__layoutContainer }` }>
        <div className={ `g-row g-row--match-height ${ ! reverseLayout && 'g-row--reorder-2-col' }` }>
          <div className="g-col g-col--one-half">
            <div className={ `${ styles.component__content } ${ reverseLayout && styles.component__contentReverse }` }>
              <h2 ref={ ref } className={ `${ styles.component__title } ${ customStyles && customStyles.component__title }` }>{ title }</h2>
              { subtitle && <p className={ `h6 ${ styles.component__subtitle } ${ customStyles && customStyles.component__subtitle }` }>{ subtitle }</p> }

              { content && <p>{ content }</p> }

              <ul className={ styles.component__list }>
                { componentList ?
                  ( componentList.map( ( { headline, subhead }, index ) => (
                        <li key={ index }>
                          <h5>{ headline }</h5>
                          <p>{ subhead }</p>
                        </li>
                  ) ) ) :
                  ( items ? items.map( ( item, index ) => (
                      <li key={ index }>
                        <span className={ `icon icon-checkmark1 ${ styles.component__icon } ${ theme && styles[ `component__icon__${ theme }` ] }` } />
                        { item }
                      </li>
                  ) ) :
                    ''
                  )
                }
              </ul>
              <p className={ `legal ${ styles.component__legal }` }>{ legal }</p>

              { ctaLink ? (
                <DynamicButton className={ `btn ${ dualCta && styles.component__buttonStyle } ${ ctaTheme && `btn--${ ctaTheme }` }` } position={ position } href={ ctaLink?.href }>
                  { ctaLink?.text }
                </DynamicButton>
              ) : (
                <DynamicButton
                  className={ `btn ${ dualCta && styles.component__buttonStyle } ${ ctaTheme && `btn--${ ctaTheme }` }` }
                  type="cart"
                  position={ position }
                />
              ) }

              { dualCta &&
                <DynamicButton
                  className={ `btn btn--blackOutline ${ styles.component__buttonStyle } ${ dualCtaTheme && `btn--${ dualCtaTheme }` }` }
                  href={ dualCta?.href }
                  position={ position }>
                  { dualCta?.text }
                </DynamicButton>
              }
            </div>{ /* <!-- .component__content --> */}
          </div>{ /* <!-- .g-col --> */}

          <div className="g-col g-col--one-half">
            <div className={ `${ styles.component__backgroundWrap } ${ reverseLayout && styles.component__backgroundWrapReverse } ${ customStyles && customStyles.component__backgroundWrap }` }>
              <div className={ `${ styles.component__background }` }>
                { video ?
                  <video autoPlay loop muted>
                    <source src={ video } type="video/mp4" />
                  </video> :
                  <Image
                    alt={ image.alt }
                    src={ image.url }
                    loader={ fastly }
                    placeholder="blur"
                    blurDataURL={ fastlyBlur( image.blurUrl ) }
                    layout="fill"
                    loading="lazy"
                    objectFit={ objectFit }
                    objectPosition={ objectPosition }
                  />
                }
              </div>
            </div>{ /* <!-- .component__backgroundWrap --> */ }
          </div>{ /* <!-- .g-col --> */ }
        </div>{ /* <!-- .g-row --> */ }
      </div>
    </section>
  );
};

Component.defaultProps = {
  assetName: 'SECTION | SPLIT SECTION | DEFAULT',
  title: <>Headline</>,
  subtitle: null,
  componentList: null,
  items: null,
  legal: '',
  image: defaultImage,
  video: null,
  theme: null,
  reverseLayout: false
};

Component.propTypes = {
  assetName: PropTypes.string,
  pretitle: PropTypes.string,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  image: PropTypes.shape( {
    url: PropTypes.string,
    blurUrl: PropTypes.string,
    alt: PropTypes.string
  } ),
  video: PropTypes.string,
  items: PropTypes.arrayOf( PropTypes.node ),
  legal: PropTypes.string,
  theme: PropTypes.string,
  reverseLayout: PropTypes.bool,
  ctaTheme: PropTypes.string,
  customStyles: PropTypes.object
};

export default Component;
